import React, { createContext, useState, useContext } from 'react';

const ApiKeyContext = createContext();

export function ApiKeyProvider({ children }) {
  const [apiKeys, setApiKeys] = useState({
    OPENAI_API_KEY: '',
    GROQ_API_KEY: ''
  });

  const updateApiKeys = (newKeys) => {
    if (newKeys.OPENAI_API_KEY || newKeys.GROQ_API_KEY) {
      setApiKeys({
        OPENAI_API_KEY: newKeys.OPENAI_API_KEY || '',
        GROQ_API_KEY: newKeys.GROQ_API_KEY || ''
      });
    }
  };

  const clearApiKeys = () => {
    setApiKeys({
      OPENAI_API_KEY: '',
      GROQ_API_KEY: ''
    });
  };

  return (
    <ApiKeyContext.Provider value={{ apiKeys, updateApiKeys, clearApiKeys }}>
      {children}
    </ApiKeyContext.Provider>
  );
}

export function useApiKeys() {
  const context = useContext(ApiKeyContext);
  if (!context) {
    throw new Error('useApiKeys must be used within an ApiKeyProvider');
  }
  return context;
} 