import React from 'react';
import { Box, Typography, Container, Paper } from '@mui/material';
import FoxLogo from './FoxLogo';

function Dashboard() {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        mb: 4
      }}>
        <FoxLogo 
          sx={{
            width: { xs: '48px', sm: '64px' },
            height: 'auto',
            mb: 2
          }}
        />
        <Typography 
          variant="h3" 
          component="h1" 
          gutterBottom
          sx={{
            fontSize: { xs: '2rem', sm: '3rem' },
            fontWeight: 'bold',
            color: '#333'
          }}
        >
        
        </Typography>
      </Box>

      <Paper 
        elevation={3} 
        sx={{ 
          p: { xs: 2, sm: 3 },
          borderRadius: 2,
          backgroundColor: '#fff'
        }}
      >
        <Typography 
          variant="h5" 
          gutterBottom
          sx={{
            fontSize: { xs: '1.2rem', sm: '1.5rem' },
            fontWeight: 'medium',
            color: '#444'
          }}
        >
          Willkommen bei Tenderfuchs
        </Typography>
        <Typography 
          variant="body1"
          sx={{
            fontSize: { xs: '0.9rem', sm: '1rem' },
            color: '#666',
            lineHeight: 1.6
          }}
        >
          Tenderfuchs ist Ihr intelligenter Assistent für die Erstellung von Leistungsbeschreibungen. 
          Mit modernster KI-Technologie unterstützen wir Sie bei der Erstellung präziser und 
          effizienter Muster für Ausschreibungsunterlagen.
        </Typography>
        <Typography 
          variant="body1" 
          sx={{ 
            mt: 2,
            fontSize: { xs: '0.9rem', sm: '1rem' },
            color: '#666',
            lineHeight: 1.6
          }}
        >
          Navigieren Sie zum Menüpunkt "Leistungsbeschreibung", um eine neue Beschreibung zu erstellen.
        </Typography>
      </Paper>
    </Container>
  );
}

export default Dashboard; 