import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Box,
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  Menu as MenuIcon,
  Description as DescriptionIcon,
  Settings as SettingsIcon,
  ExitToApp as LogoutIcon,
  Speed as SpeedIcon,
  Home as HomeIcon
} from '@mui/icons-material';

// Default function for onLogout
const defaultLogout = () => {
  console.warn('onLogout prop not provided to Navigation component');
};

function Navigation({ onLogout = defaultLogout }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };

  const menuItems = [
    { text: 'Dashboard', icon: <HomeIcon />, path: '/' },
    { text: 'Leistungsbeschreibung', icon: <DescriptionIcon />, path: '/leistungsbeschreibung' },
    { text: 'Einstellungen', icon: <SettingsIcon />, path: '/settings' },
    { text: 'API Status', icon: <SpeedIcon />, path: '/api-test' },
    { text: 'Abmelden', icon: <LogoutIcon />, onClick: handleLogout }
  ];

  const drawer = (
    <Box sx={{ width: 250 }}>
      <Box
        sx={{ 
          p: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 1
        }}
      >
        <Typography variant="h6" sx={{ color: '#ff4444' }}>
          Tenderfuchs
        </Typography>
      </Box>
      <List>
        {menuItems.map((item, index) => (
          <React.Fragment key={item.text}>
            {index === menuItems.length - 2 && <Box sx={{ mt: 2 }} />}
            <ListItem
              button
              onClick={() => {
                if (item.onClick) {
                  item.onClick();
                } else {
                  navigate(item.path);
                }
                if (isMobile) {
                  handleDrawerToggle();
                }
              }}
              selected={item.path && location.pathname === item.path}
              sx={{ 
                '&.Mui-selected': {
                  backgroundColor: 'rgba(255, 68, 68, 0.1)',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 68, 68, 0.2)',
                  },
                },
                '&:hover': {
                  backgroundColor: 'rgba(255, 68, 68, 0.05)',
                },
              }}
            >
              <ListItemIcon sx={{ color: item.path && location.pathname === item.path ? '#ff4444' : 'inherit' }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText 
                primary={item.text}
                sx={{ 
                  '& .MuiListItemText-primary': {
                    color: item.path && location.pathname === item.path ? '#ff4444' : 'inherit',
                  },
                }}
              />
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Mobile AppBar */}
      {isMobile && (
        <AppBar 
          position="fixed" 
          sx={{ 
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: '#fff',
            boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
            height: '48px'
          }}
        >
          <Toolbar sx={{ minHeight: '48px !important', paddingLeft: 1, paddingRight: 1 }}>
            <IconButton
              color="inherit" 
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ color: '#666' }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="subtitle1" noWrap component="div" sx={{ color: '#666' }}>
              {menuItems.find(item => item.path === location.pathname)?.text || 'Tenderfuchs'}
            </Typography>
          </Toolbar>
        </AppBar>
      )}

      <Box
        component="nav"
        sx={{ width: { sm: 250 }, flexShrink: { sm: 0 } }}
      >
        {/* Mobile drawer */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { 
              width: 250,
              backgroundColor: '#fff',
              borderRight: '1px solid rgba(0, 0, 0, 0.08)'
            },
          }}
        >
          {drawer}
        </Drawer>
        {/* Desktop drawer */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { 
              width: 250,
              backgroundColor: '#fff',
              borderRight: '1px solid rgba(0, 0, 0, 0.08)',
              boxSizing: 'border-box'
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 2, sm: 3 },  // Reduced padding on mobile
          width: { sm: `calc(100% - 250px)` },
          mt: { xs: '48px', sm: 0 }  // Only add top margin on mobile
        }}
      >
        {/* Main content */}
      </Box>
    </Box>
  );
}

Navigation.propTypes = {
  onLogout: PropTypes.func
};

export default Navigation; 