import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
  Card,
  CardContent,
  Alert,
  Snackbar,
  Tooltip,
} from '@mui/material';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Upload as UploadIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { useApiKeys } from '../context/ApiKeyContext';

const Settings = () => {
  const { apiKeys, updateApiKeys, clearApiKeys } = useApiKeys();
  const [showKeys, setShowKeys] = useState({
    OPENAI_API_KEY: false,
    GROQ_API_KEY: false,
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const text = e.target.result;
          const envVars = {};
          text.split('\n').forEach(line => {
            if (line.trim() && !line.startsWith('#')) {
              const [key, ...valueParts] = line.split('=');
              const value = valueParts.join('=');
              if (key && value) {
                envVars[key.trim()] = value.trim();
              }
            }
          });
          
          const newApiKeys = {
            OPENAI_API_KEY: envVars.OPENAI_API_KEY || '',
            GROQ_API_KEY: envVars.GROQ_API_KEY || '',
          };

          // Validate keys before saving
          if (!newApiKeys.OPENAI_API_KEY && !newApiKeys.GROQ_API_KEY) {
            throw new Error('Keine gültigen API-Keys in der Datei gefunden');
          }

          // Update context
          updateApiKeys(newApiKeys);

          setSnackbar({
            open: true,
            message: 'API-Keys erfolgreich geladen',
            severity: 'success',
          });
        } catch (error) {
          console.error('Error parsing .env file:', error);
          setSnackbar({
            open: true,
            message: error.message || 'Fehler beim Laden der .env Datei',
            severity: 'error',
          });
        }
      };
      reader.readAsText(file);
    }
  };

  const toggleKeyVisibility = (key) => {
    setShowKeys(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  const handleClearKeys = () => {
    clearApiKeys();
    setSnackbar({
      open: true,
      message: 'API-Keys wurden gelöscht',
      severity: 'info',
    });
  };

  const maskApiKey = (key) => {
    if (!key) return '';
    if (key.length <= 6) return '*'.repeat(key.length);
    return `${key.substring(0, 3)}${'*'.repeat(key.length - 6)}${key.substring(key.length - 3)}`;
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Einstellungen
      </Typography>

      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
            <Typography variant="h6">
              API-Keys verwalten
            </Typography>
            <Box>
              <Button
                variant="contained"
                component="label"
                startIcon={<UploadIcon />}
                sx={{ mr: 2 }}
              >
                .env hochladen
                <input
                  type="file"
                  accept=".env"
                  hidden
                  onChange={handleFileUpload}
                />
              </Button>
              <Tooltip title="Alle API-Keys löschen">
                <IconButton 
                  onClick={handleClearKeys}
                  color="error"
                  sx={{ 
                    '&:hover': { 
                      bgcolor: 'error.lighter',
                    }
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <TextField
              fullWidth
              label="OpenAI API Key"
              value={showKeys.OPENAI_API_KEY ? apiKeys.OPENAI_API_KEY : maskApiKey(apiKeys.OPENAI_API_KEY)}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <IconButton
                    onClick={() => toggleKeyVisibility('OPENAI_API_KEY')}
                    edge="end"
                  >
                    {showKeys.OPENAI_API_KEY ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                ),
              }}
            />

            <TextField
              fullWidth
              label="Groq API Key"
              value={showKeys.GROQ_API_KEY ? apiKeys.GROQ_API_KEY : maskApiKey(apiKeys.GROQ_API_KEY)}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <IconButton
                    onClick={() => toggleKeyVisibility('GROQ_API_KEY')}
                    edge="end"
                  >
                    {showKeys.GROQ_API_KEY ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                ),
              }}
            />
          </Box>

          <Typography variant="body2" color="text.secondary" sx={{ mt: 3 }}>
            Laden Sie Ihre .env Datei mit den API-Keys für OpenAI und Groq hoch. 
            Die Keys werden sicher in der Session gespeichert und beim Abmelden automatisch gelöscht.
          </Typography>
        </CardContent>
      </Card>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Settings; 