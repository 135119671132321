import React, { useState } from 'react';
import { 
  createBrowserRouter, 
  RouterProvider, 
  createRoutesFromElements,
  Route,
  Navigate,
  Outlet
} from 'react-router-dom';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import Navigation from './components/Navigation';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import LeistungsbeschreibungForm from './components/LeistungsbeschreibungForm';
import Settings from './components/Settings';
import ApiTest from './components/ApiTest';
import { ApiKeyProvider } from './context/ApiKeyContext';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = (credentials) => {
    console.log('Login handler called with:', credentials);
    if (credentials.username === 'admin' && credentials.password === 'admin') {
      console.log('Credentials match, setting authenticated to true');
      setIsAuthenticated(true);
      return true;
    }
    console.log('Invalid credentials');
    return false;
  };

  const handleLogout = () => {
    console.log('Logging out, setting authenticated to false');
    setIsAuthenticated(false);
  };

  // Layout component for authenticated routes
  const AuthLayout = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
      <Box sx={{ display: 'flex' }}>
        <Navigation onLogout={handleLogout} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            pt: { xs: '64px', sm: 0 },  // Responsive padding top
            px: { xs: 2, sm: 3 },       // Responsive horizontal padding
            pb: { xs: 2, sm: 3 },       // Responsive bottom padding
            minHeight: '100vh',         // Ensure full height
            backgroundColor: '#fff'      // White background
          }}
        >
          <Outlet />
        </Box>
      </Box>
    );
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: isAuthenticated ? (
        <AuthLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        {
          path: "/",
          element: <Dashboard />
        },
        {
          path: "/leistungsbeschreibung",
          element: <LeistungsbeschreibungForm />
        },
        {
          path: "/settings",
          element: <Settings />
        },
        {
          path: "/api-test",
          element: <ApiTest />
        }
      ]
    },
    {
      path: "/login",
      element: !isAuthenticated ? (
        <Login onLogin={handleLogin} />
      ) : (
        <Navigate to="/" replace />
      )
    }
  ], {
    future: {
      v7_startTransition: true,
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true
    }
  });

  return (
    <ApiKeyProvider>
      <RouterProvider router={router} />
    </ApiKeyProvider>
  );
}

export default App; 