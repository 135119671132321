import React, { useState, useEffect, useCallback } from 'react';
import { Box, Paper, Typography } from '@mui/material';

function ApiTest() {
  const [apiStatus, setApiStatus] = useState(null);

  const checkApiStatus = useCallback(async () => {
    try {
      const res = await fetch('/api/status');  // Check status endpoint
      setApiStatus(res.ok);
    } catch (error) {
      setApiStatus(false);
    }
  }, []);

  useEffect(() => {
    // Initial check
    checkApiStatus();

    // Set up interval for checking every 3 minutes
    const interval = setInterval(checkApiStatus, 180000);

    return () => clearInterval(interval);
  }, [checkApiStatus]);

  return (
    <Box sx={{ p: { xs: 2, sm: 3 } }}>
      <Paper 
        elevation={3} 
        sx={{ 
          p: { xs: 2, sm: 3 },
          borderRadius: 2,
          backgroundColor: '#fff'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              width: 12,
              height: 12,
              borderRadius: '50%',
              backgroundColor: apiStatus === null ? '#999' : apiStatus ? '#4caf50' : '#f44336',
              mr: 2
            }}
          />
          <Typography 
            variant="body1" 
            sx={{
              color: '#666',
              fontSize: { xs: '0.9rem', sm: '1rem' }
            }}
          >
            API Status: {apiStatus === null ? 'Prüfe...' : apiStatus ? 'Verbunden' : 'Nicht verfügbar'}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
}

export default ApiTest; 