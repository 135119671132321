export const downloadBase64File = (base64Data, fileName, fileType) => {
    try {
        // Check if the data is empty
        if (!base64Data) {
            throw new Error('No data received');
        }

        // Extract base64 data from data URI if present
        let base64String = base64Data;
        if (base64Data.startsWith('data:')) {
            const matches = base64Data.match(/^data:[^;]+;base64,(.+)$/);
            if (matches && matches[1]) {
                base64String = matches[1];
            } else {
                throw new Error('Invalid data URI format');
            }
        }

        // Validate base64 string
        if (!/^[A-Za-z0-9+/]*={0,2}$/.test(base64String)) {
            throw new Error('Invalid base64 format');
        }
        
        // Convert base64 to blob
        const byteCharacters = atob(base64String);
        const byteArrays = [];
        
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            
            byteArrays.push(new Uint8Array(byteNumbers));
        }
        
        const blob = new Blob(byteArrays, { type: fileType });
        
        // Create download link
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        
        // Trigger download
        document.body.appendChild(link);
        link.click();
        
        // Cleanup
        setTimeout(() => {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
        }, 100);
    } catch (error) {
        console.error('Error downloading file:', error);
        alert('Fehler beim Herunterladen der Datei: ' + error.message);
    }
};

export const getFileTypeAndName = (format) => {
    switch (format) {
        case 'pdf':
            return {
                fileType: 'application/pdf',
                extension: '.pdf'
            };
        case 'docx':
            return {
                fileType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                extension: '.docx'
            };
        default:
            return {
                fileType: 'text/plain',
                extension: '.txt'
            };
    }
}; 