import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Container,
  Alert
} from '@mui/material';

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    console.log('Login component mounted');
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted');
    console.log('Current form values:', { username, password });
    
    try {
      const success = onLogin({ username, password });
      console.log('Login result:', success);
      
      if (!success) {
        console.log('Setting error message');
        setError('Ungültige Anmeldedaten');
        setTimeout(() => {
          console.log('Clearing error message');
          setError('');
        }, 3000);
      }
    } catch (err) {
      console.error('Error during login:', err);
      setError('Ein Fehler ist aufgetreten');
    }
  };

  const handleUsernameChange = (e) => {
    console.log('Username changed:', e.target.value);
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    console.log('Password changed');
    setPassword(e.target.value);
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography 
          component="h1" 
          variant="h4" 
          sx={{ 
            mt: 2, 
            mb: 3,
            color: '#333',
            fontWeight: 'bold'
          }}
        >
          Tenderfuchs
        </Typography>
        <Paper
          elevation={3}
          sx={{
            p: { xs: 3, sm: 4 },
            width: '100%',
            borderRadius: 2,
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" sx={{ mb: 3, color: '#444' }}>
                Anmeldung
              </Typography>
              {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              )}
              <TextField
                fullWidth
                label="Benutzername"
                value={username}
                onChange={handleUsernameChange}
                margin="normal"
                required
                autoFocus
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Passwort"
                type="password"
                value={password}
                onChange={handlePasswordChange}
                margin="normal"
                required
                sx={{ mb: 3 }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 2,
                  backgroundColor: '#ff4444',
                  '&:hover': {
                    backgroundColor: '#ff2222',
                  },
                }}
              >
                Anmelden
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>
    </Container>
  );
}

export default Login; 