import * as React from "react";

const FoxLogo = (props) => (
  <svg
    width={36}
    height={36}
    viewBox="0 0 36 36"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <path
      fill="#ff8c00"
      d="M13.431 9.802c.658 2.638-8.673 10.489-11.244 4.098C.696 10.197-.606 2.434.874 2.065c1.48-.368 11.9 5.098 12.557 7.737"
    />
    <path
      fill="#e67300"
      d="M11.437 10.355c.96 1.538-1.831 4.561-3.368 5.522-1.538.961-2.899-.552-4.414-4.414-.662-1.689-1.666-6.27-1.103-6.622.562-.351 7.924 3.976 8.885 5.514"
    />
    <path
      fill="#ff8c00"
      d="M22.557 9.802C21.9 12.441 31.23 20.291 33.802 13.9c1.49-3.703 2.792-11.466 1.312-11.835-1.48-.368-11.899 5.098-12.557 7.737"
    />
    <path
      fill="#e67300"
      d="M24.552 10.355c-.96 1.538 1.831 4.561 3.368 5.522s2.898-.552 4.413-4.414c.662-1.688 1.666-6.269 1.104-6.621-.563-.352-7.924 3.975-8.885 5.513"
    />
    <path
      fill="#ff8c00"
      d="M32.347 26.912c0-.454-.188-1.091-.407-1.687.585.028 1.519.191 2.77.817a4 4 0 0 0-.273-1.393c-1.103-3.31-3.309-5.517-3.309-5.517h2.206c-2.331-4.663-4.965-8.015-8.075-9.559-1.39-.873-3.688-1.338-7.373-1.339h-.003c-3.695 0-5.996.468-7.385 1.346-3.104 1.547-5.734 4.896-8.061 9.552H4.76s-2.207 2.206-3.311 5.517a2.7 2.7 0 0 0-.282 1.377c1.263-.632 2.217-.792 2.813-.818-.189.513-.343 1.044-.386 1.475 3.156-.099 4.656.109 6.406.984C11.213 31.29 14.206 34 18.001 34c3.793 0 6.746-2.794 7.958-6.416 1.458-1.25 3.708-.875 6.416.416z"
    />
    <path
      fill="#ff9f1a"
      d="M31.243 23.601c.006 0 1.108.003 3.309 1.103-1.249-2.839-7.525-4.07-9.931-3.291-1.171 1.954-1.281 5.003-3.383 6.622-1.741 1.431-4.713 1.458-6.479 0-2.345-1.924-2.559-5.813-3.382-6.622-2.407-.781-8.681.454-9.931 3.291 2.201-1.101 3.304-1.103 3.309-1.103 0 .001-1.103 2.208-1.103 3.311 2.398-1.573 5.116-2.271 7.429-.452 1.666 7.921 12.293 7.545 13.833 0 2.314-1.818 5.03-1.122 7.429.452.002-1.103-1.101-3.311-1.102-3.311z"
    />
    <path
      fill="#333"
      d="M11 17s0-1.5 1.5-1.5S14 17 14 17v1.5s0 1.5-1.5 1.5-1.5-1.5-1.5-1.5zm11 0s0-1.5 1.5-1.5S25 17 25 17v1.5s0 1.5-1.5 1.5-1.5-1.5-1.5-1.5zm-7.061 10.808c-1.021.208 2.041 3.968 3.062 3.968s4.082-3.76 3.062-3.968-5.103-.208-6.124 0"
    />
  </svg>
);

export default FoxLogo; 