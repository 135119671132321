import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  TextField,
  Button,
  Typography,
  Grid,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Card,
  CardContent,
  CircularProgress,
  Alert,
  ToggleButtonGroup,
  ToggleButton,
  LinearProgress,
  Stack,
  Divider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useApiKeys } from '../context/ApiKeyContext';
import { downloadBase64File, getFileTypeAndName } from '../utils/documentUtils';

function LeistungsbeschreibungForm() {
  const navigate = useNavigate();
  const { apiKeys } = useApiKeys();
  const [request, setRequest] = useState({
    text: "",
    structure_config: {
      model: "mixtral-8x7b-32768",
      api_url: "https://api.groq.com/openai/v1/chat/completions",
      api_key: "",
      max_tokens: 4000,
      temperature: 0.7
    },
    detail_config: {
      model: "mixtral-8x7b-32768",
      api_url: "https://api.groq.com/openai/v1/chat/completions",
      api_key: "",
      max_tokens: 4000,
      temperature: 0.7
    },
    formats: ["text"]
  });
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [provider, setProvider] = useState(null);
  const [jobStatus, setJobStatus] = useState(null);

  // Set initial provider based on available keys
  useEffect(() => {
    if (apiKeys.GROQ_API_KEY) {
      setProvider('groq');
    } else if (apiKeys.OPENAI_API_KEY) {
      setProvider('openai');
    }
  }, [apiKeys]);

  const handleProviderChange = (event, newProvider) => {
    if (!newProvider) return;
    
    const hasKey = newProvider === 'openai' ? 
      Boolean(apiKeys.OPENAI_API_KEY) : 
      Boolean(apiKeys.GROQ_API_KEY);

    if (!hasKey) {
      setError(`Kein API-Key für ${newProvider === 'openai' ? 'OpenAI' : 'Groq'} gefunden. Bitte in den Einstellungen konfigurieren.`);
      return;
    }

    setError(null);
    setProvider(newProvider);
    const config = {
      model: newProvider === 'openai' ? 'gpt-4' : 'mixtral-8x7b-32768',
      api_url: newProvider === 'openai' 
        ? 'https://api.openai.com/v1/chat/completions'
        : 'https://api.groq.com/openai/v1/chat/completions',
      api_key: "",
      max_tokens: 4000,
      temperature: 0.7
    };
    setRequest(prev => ({
      ...prev,
      structure_config: config,
      detail_config: config
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!provider) {
      setError('Bitte wählen Sie einen KI-Provider aus.');
      return;
    }

    const apiKey = provider === 'openai' ? apiKeys.OPENAI_API_KEY : apiKeys.GROQ_API_KEY;
    if (!apiKey) {
      setError(`Kein API-Key für ${provider === 'openai' ? 'OpenAI' : 'Groq'} gefunden.`);
      navigate('/settings');
      return;
    }

    setLoading(true);
    setError(null);
    setResponse(null);
    setJobStatus('processing');

    try {
      const requestWithKey = {
        ...request,
        structure_config: {
          ...request.structure_config,
          api_key: apiKey
        },
        detail_config: {
          ...request.detail_config,
          api_key: apiKey
        }
      };

      const res = await fetch('/api/vergabe/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestWithKey),
      });
      
      if (!res.ok) {
        const errorData = await res.text();
        let errorMessage;
        try {
          const parsedError = JSON.parse(errorData);
          errorMessage = parsedError.detail || parsedError.message || 'Ein Fehler ist aufgetreten';
        } catch {
          errorMessage = errorData || 'Ein Fehler ist aufgetreten';
        }
        throw new Error(errorMessage);
      }
      
      const data = await res.json();
      if (!data || !data.text) {
        throw new Error('Ungültiges Antwortformat vom Server');
      }
      
      setResponse(data);
      setJobStatus('completed');
    } catch (error) {
      console.error('API Error:', error);
      setError(error.message);
      setJobStatus('failed');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (field, value) => {
    setRequest(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleFormatChange = (format) => {
    setRequest(prev => ({
      ...prev,
      formats: prev.formats.includes(format)
        ? prev.formats.filter(f => f !== format)
        : [...prev.formats, format]
    }));
  };

  const handleDownload = (format, content) => {
    const { fileType, extension } = getFileTypeAndName(format);
    const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
    const fileName = `leistungsbeschreibung-${timestamp}${extension}`;
    
    if (format === 'text') {
      // For text format, create a text file
      const textBlob = new Blob([content], { type: 'text/plain' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(textBlob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } else {
      // For PDF and DOCX formats, use base64 download
      downloadBase64File(content, fileName, fileType);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'processing': return 'info';
      case 'completed': return 'success';
      case 'failed': return 'error';
      default: return 'default';
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Leistungsbeschreibung Generator
      </Typography>
      
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <form onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <FormControl fullWidth>
                  <Typography variant="subtitle1" gutterBottom>
                    KI-Modell
                  </Typography>
                  <ToggleButtonGroup
                    value={provider}
                    exclusive
                    onChange={handleProviderChange}
                    fullWidth
                  >
                    <ToggleButton value="openai">
                      OpenAI GPT-4
                    </ToggleButton>
                    <ToggleButton value="groq">
                      Groq Mixtral
                    </ToggleButton>
                  </ToggleButtonGroup>
                </FormControl>

                <FormControl fullWidth>
                  <Typography variant="subtitle1" gutterBottom>
                    Beschreibung der Leistung
                  </Typography>
                  <TextField
                    multiline
                    rows={4}
                    value={request.text}
                    onChange={(e) => handleChange('text', e.target.value)}
                    placeholder="Beschreiben Sie die gewünschte Leistung..."
                    required
                  />
                </FormControl>

                <FormControl fullWidth>
                  <Typography variant="subtitle1" gutterBottom>
                    Ausgabeformate
                  </Typography>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={request.formats.includes('text')}
                          onChange={() => handleFormatChange('text')}
                        />
                      }
                      label="Text"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={request.formats.includes('pdf')}
                          onChange={() => handleFormatChange('pdf')}
                        />
                      }
                      label="PDF"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={request.formats.includes('docx')}
                          onChange={() => handleFormatChange('docx')}
                        />
                      }
                      label="DOCX"
                    />
                  </FormGroup>
                </FormControl>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={loading}
                  sx={{ mt: 2 }}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Leistungsbeschreibung generieren'
                  )}
                </Button>
              </Stack>
            </form>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Generierte Leistungsbeschreibung
            </Typography>

            {jobStatus && (
              <Box sx={{ mb: 3 }}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>Status:</Typography>
                  <Alert severity={getStatusColor(jobStatus)} sx={{ py: 0 }}>
                    {jobStatus === 'processing' ? 'Wird generiert...' :
                     jobStatus === 'completed' ? 'Fertig' :
                     'Fehler'}
                  </Alert>
                </Stack>
                {jobStatus === 'processing' && <LinearProgress sx={{ mt: 1 }} />}
              </Box>
            )}

            {response ? (
              <Box>
                {Object.entries(response.text).map(([format, content]) => (
                  <Card key={format} sx={{ mb: 2 }}>
                    <CardContent>
                      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
                        <Typography variant="subtitle1">
                          {format === 'text' ? 'Text' :
                           format === 'pdf' ? 'PDF' :
                           'Word'} Version
                        </Typography>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => handleDownload(format, content)}
                        >
                          Download
                        </Button>
                      </Stack>
                      <Divider sx={{ my: 1 }} />
                      {format === 'text' ? (
                        <Typography
                          component="pre"
                          sx={{
                            whiteSpace: 'pre-wrap',
                            fontFamily: 'inherit',
                            mt: 2
                          }}
                        >
                          {content}
                        </Typography>
                      ) : (
                        <Typography color="text.secondary">
                          {format === 'pdf' ? 'PDF' : 'Word'} Datei bereit zum Download
                        </Typography>
                      )}
                    </CardContent>
                  </Card>
                ))}
              </Box>
            ) : (
              <Typography color="text.secondary">
                Noch keine Leistungsbeschreibung generiert
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default LeistungsbeschreibungForm; 